<template>
  <div>
    <div class="customerMapSearch" v-if="mapType == 1 || mapType == 2">
      <el-autocomplete
        v-model="companyNameCopy"
        :fetch-suggestions="getCompanyArraySelect"
        :placeholder="$t('tracking.Input Company Name')"
        clearable
        value-key="label"
        @select="customerMapSelect"
        v-if="params.type == 'Company'"
        @clear="clearCompanyName()"
        style="width: 160px;"
        suffix-icon="el-icon-search"
        size="small"
      />
    </div>
    <div class="mapShowModeTabs" v-if="companyCurrentType != 3">
      <div class="item" :class="mapType == 0 ? 'active' : ''" @click="mapType = 0">
        <i class="iconfont icon-order-3"></i>
        {{ $t('Logistics map') }}
      </div>
      <div class="item" :class="mapType == 1 ? 'active' : ''" @click="mapType = 1">
        <i class="iconfont icon-gongyingshang"></i>
        {{ companyCurrentType == 0 ? $t('Supplier Map') : companyCurrentType == 1 ? $t('Customer Map') : companyCurrentType == 2 ? $t('Supplier Map') : $t('Customer Map') }}
      </div>
      <div class="item" v-if="companyCurrentType == 2" :class="mapType == 2 ? 'active' : ''" @click="mapType = 2">
        <i class="iconfont icon-gongyingshang"></i>
        {{ $t('Customer Map') }}
      </div>
    </div>
    <div class="search_bar" v-if="mapType == 0">
      <pageTable 
        :isForm="true"
        :searchParams.sync="params"
        @searchEvent="nextFilterDialog">
        <template #searchForm>
          <div class="translateButton">
            <el-tabs class="special_tabs" v-model="params.type">
              <el-tab-pane :label="$t(`tracking.Company`)" name="Company" />
              <el-tab-pane :label="$t(`tracking.CI`)" name="CI" />
            </el-tabs>
          </div>
          <el-autocomplete
            v-model="params.name"
            :fetch-suggestions="getCompanyArraySelect"
            :placeholder="$t('tracking.Input Company Name')"
            clearable
            value-key="label"
            @select="setObj"
            v-if="params.type == 'Company'"
            @clear="clear()"
            style="width: 160px;"
            suffix-icon="el-icon-search"
            size="small"
            @keyup.enter.native="nextFilterDialog"
          />
          <el-input
            :placeholder="$t('tracking.Input CI')"
            v-model="params.ci"
            style="width: 160px;"
            auto-complete="off"
            suffix-icon="el-icon-search"
            clearable
            size="small"
            @keyup.enter.native="nextFilterDialog"
            v-else
          />
        </template>
      </pageTable>
      <div 
        class="scaleTableBox" 
        :style="`
          transform: translateX(${isScaleTable ? '0px' : '-600px'});
        `">
        <div class="btns">
          <div class="headerBtn">
            <div class="btn" :tooltipTitle="$t('tracking.Open')" @click="isScaleTable = false">
              <i class="iconfont icon-log-out"></i>
            </div>
          </div>
          <div class="headerBtn">
            <div class="btn" @click="() => {
              isShowTable = false;
              isScaleTable = false;
            }">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        class="orderListDialog"
        :style="`
          transform: translateX(${(isShowTable && !isScaleTable) ? '0px' : '-600px'});
        `"
        v-loading="loading"
      >
        <div class="header">
          <span>{{ params.name || $tc("order.Order List", $isOrderTitle()) }}</span>
          <div class="btns">
            <div class="headerBtn">
              <div class="btn" :tooltipTitle="$t('tracking.Stow')" @click="isScaleTable = true;">
                <i class="iconfont icon-log-out"></i>
              </div>
            </div>
            <div class="headerBtn">
              <div class="btn" @click="isShowTable = !isShowTable">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="content listContent">
          <div class="orderInfo" v-if="orderInfo && params.type != 'CI'">
            <div class="total" @click="searchParams.orderProgress = []">
              <span>
                <i class="iconfont icon-dingdanshu1"></i>{{ orderInfo.order }}
              </span>
              {{ $tc('home.OrdersTotal', $isOrderTitle()) }}
            </div>
            <div class="orderNum">
              <div v-show="isDefaultNode" class="item" @click="searchParams.orderProgress = [0]">
                <span>{{ orderInfo.production || 0 }}</span>
                {{ $t("Dashboard.Producing") }}
              </div>
              <el-dropdown
                v-if="isDefaultNode"
                trigger="click" 
                placement="bottom-start"
                @visible-change="visibleDropdownChange">
                <div 
                  class="item" 
                  :class="tableSelectedShow === 'transport' ? 'active' : ''"
                  @click="tableSelectedShow = 'transport'">
                  <span>{{ orderInfo.transport || 0 }}</span>
                  {{ $t("Dashboard.Shipping") }}
                </div>
                <el-dropdown-menu class="ec-popper" slot="dropdown">
                  <el-dropdown-item
                    class="popperMenuItem"
                    @click.native="searchParams.orderProgress = [1]"
                  ><div class="drop"></div>{{ orderProgress[1].key }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    @click.native="searchParams.orderProgress = [2]"
                  ><div class="drop"></div>{{ orderProgress[2].key }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    @click.native="searchParams.orderProgress = [3]"
                  ><div class="drop"></div>{{ orderProgress[3].key }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    @click.native="searchParams.orderProgress = [4]"
                  ><div class="drop"></div>{{ orderProgress[4].key }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    @click.native="searchParams.orderProgress = [5]"
                  ><div class="drop"></div>{{ orderProgress[5].key }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div 
                v-else
                class="item" 
                :class="tableSelectedShow === 'transport' ? 'active' : ''"
                @click="tableSelectedShow = 'transport'">
                <span>{{ orderInfo.transport || 0 }}</span>
                {{ $t("order.pending") }}
              </div>
              <div class="item" @click="searchParams.orderProgress = [6]">
                <span>{{ orderInfo.completed || 0 }}</span>
                {{ $t("Dashboard.Completed") }}
              </div>
            </div>
          </div>
          <div class="form" v-if="params.type != 'CI'">
            <el-date-picker
              size="small"
              v-model="dateRange"
              type="daterange"
              :start-placeholder="$t('tracking.Start from')"
              :end-placeholder="$t('tracking.End to')"
              style="width: 200px;"
              prefix-icon="iconfont icon-Calendar"
            ></el-date-picker>
            <div class="all">
              全部轨迹<i class="el-icon-location-outline"></i>
            </div>
            <el-input
              size="small"
              v-model="cardCi"
              :placeholder="$t('tracking.Input CI')"
              clearable
              style="width: 68.5%;"
              @keyup.enter.native="nextFilterDialog()"
              @clear="nextFilterDialog()"
            >
              <i slot="suffix" class="el-icon-search" style="cursor: pointer;" @click="nextFilterDialog()"></i>
            </el-input>
            <chat-select
              size="small"
              v-model="sortBy"
              :placeholder="$t('InformationCenter.Sortby')"
              clearable
              style="width: 100px;"
            >
              <el-option value="ci|asc" :label="$t('tracking.CI')+$t('home.Asc')" />
              <el-option value="ci|desc" :label="$t('tracking.CI')+$t('home.Desc')" />
              <el-option value="amount|asc" :label="$t('tracking.Amount')+$t('home.Asc')" />
              <el-option value="amount|desc" :label="$t('tracking.Amount')+$t('home.Desc')" />
              <el-option value="createTime|asc" :label="$t('tracking.Create Time')+$t('home.Asc')" />
              <el-option value="createTime|desc" :label="$t('tracking.Create Time')+$t('home.Desc')" />
            </chat-select>
          </div>
          <div class="orderListBox" v-if="arrList.length">
            <div 
              class="item" 
              v-for="(item, index) in arrList" 
              :key="index" 
              @click="currentShipInfo(item)">
              <div class="orderContent">
                <div class="ciNo">
                  <span class="index">{{ item.index }}</span>
                  <span class="ci" v-showTootip>{{ item.ci ? 'CI' : $isOrderPITitle() }}：{{ item.ci || item.pi }}</span>
                </div>
                <div class="stateAmount">
                  <span class="amount">{{ item.currency }}：{{ item.amount }}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span class="state">
                    <div class="dropState" :class="stateDrop(item)" />{{ computedNodeList(item.progress).name }}
                  </span>
                </div>
                <div class="createTime">{{ item.createTime }}</div>
              </div>
              <div class="action">
                <i class="iconfont icon-xiangqing"></i>
                <el-checkbox 
                  @click.stop.native 
                  v-model="item.checked" 
                  @change="search(item)" 
                />
              </div>
            </div>
          </div>
          <el-empty 
            :description="$t('el.table.emptyText')" 
            :image="$emptyImage"
            :image-size="90"
            v-if="!arrList.length"
          ></el-empty>
          <div class="pagination">
            <div class="total">
              {{ $tc('in total', searchParams.total) }}
            </div>
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :total="searchParams.total"
              :current-page="searchParams.currentPage"
              :page-size="5"
              :pager-count="5"
              @size-change="changePageSizeHandler"
              @current-change="changePageHandler">
            </el-pagination>
          </div>
        </div>
      </div>
      <div
        class="orderListDialog"
        :style="`transform: translateX(${
          orderInfoBlock ? '0px' : '-600px'
        });`"
        v-loading="loading"
      >
        <div class="header">
          <div class="left">
            <i class="el-icon-arrow-left" @click="
              (orderInfoBlock = !orderInfoBlock), (isShowTable = !isShowTable)
            "></i>
            <toolTipIcon 
              icon="iconfont icon-fenxiang" 
              :tooltipText="$t('order.share')"
              @click="goShareOrder"
            />
          </div>
          <span>{{ (order && order.ci) ? 'CI' : $isOrderPITitle() }}：{{ order && (order.ci || order.pi) }}</span>
        </div>
        <div class="content" v-if="order">
          <div class="userInfos" :style="{
            height: orderinfosShow ? '408px' : '150px',
            maskImage: !orderinfosShow ? 'linear-gradient(to top,rgba(255,255,255,0) 0,rgba(255,255,255,.6) 20%,rgba(255,255,255,1) 100%)' : ''
          }">
            <div class="purchaseCompany">
              <div class="purchaseCompanyInfo">
                <span>{{ order.purchase.aliasName }}</span>
                {{ $t("order.Buyer") }}
              </div>
              <el-image
                fit="cover"
                :src="order.purchase.companyLogoUrl"
              />
            </div>
            <div class="obw-grid">
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Status") }}</div>
                <div class="lb-a2">
                  <div class="flexAlignCenter" style="gap: 3px">
                    <div class="dropState" :class="stateDrop(order)" />
                    {{ computedNodeList(order.progress).name }}
                  </div>
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("order.Value") }}</div>
                <div class="lb-a2">
                  {{ order.currency }}
                  {{ order.amount }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Create Time") }}</div>
                <div class="lb-a2">
                  {{ order.createTime }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Supplier") }}</div>
                <div class="lb-a2">
                  {{
                    order.vendor.aliasName
                  }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("order.Buyer") }}</div>
                <div class="lb-a2">
                  {{
                    order.purchase.aliasName
                  }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Forwarder") }}</div>
                <div class="lb-a2" v-if="order.forwarderList.length">
                  <template v-for="(item, index) in order.forwarderList">
                    {{ item.aliasName }}{{ index != (order.forwarderList.length - 1) ? ',' : '' }}
                  </template>
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Ship info") }}</div>
                <div class="lb-a2">
                  {{ order.vessel }}/{{ order.voyage }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Weight/Dimension") }}</div>
                <div class="lb-a2">
                  {{ order.weight }}/{{ order.dimension }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Port of Loading") }}</div>
                <div class="lb-a2">
                  {{ order.pol }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Quantity") }}</div>
                <div class="lb-a2">
                  {{ order.qty }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Port of discharge") }}</div>
                <div class="lb-a2">
                  {{ order.pod }}
                </div>
              </div>
              <div class="left-block">
                <div class="lb-a1">{{ $t("tracking.Destination") }}</div>
                <div class="lb-a2">
                  {{ order.destination }}
                </div>
              </div>
            </div>
          </div>
          <div class="seeMore" @click="orderinfosShow = !orderinfosShow;">
            {{ $t('home.seeMore') }}<i class="iconfont icon-a-Polygon3" :style="{
              transform: `scale(.5) rotate(${!orderinfosShow ? '180deg' : '0deg'})`
            }"></i>
          </div>
          <div class="orderStatus">
            <div class="lineDemarcation"></div>
            <!-- <orderHistory -->
              <!-- :history="order && order.orderHistoryVOList" -->
              <!-- :type="3" -->
              <!-- :size="25" -->
            <!-- ></orderHistory> -->
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in order.progressList"
                :key="index"
                :icon="item.icon"
                :color="item.color"
                :size="item.size">
                <div class="statusHeader" v-if="computedNodeList(item.progress)">
                  <span>{{ computedNodeList(item.progress).name }}</span>
                  <span>{{ item.date }}</span>
                </div>
                <div 
                  class="userAvator" 
                  v-if="item.user"
                  :class="item.active ? 'show' : 'hide'">
                  <member
                    :info="item.user"
                    :size="18"
                  ></member>
                  <span>{{ item.user && item.user.nickName }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <mapTrack
      :type="mapType"
      :orders="mapParams.orders"
      :timeNow="mapParams.timeNow"
      :companyName="companyName"
      :isSelectlayer="true"
      @callback="currentShipInfo"
    />
    <orderShare v-if="shareOrderId" ref="orderShare" :type="1" :orderId="shareOrderId" />
  </div>
</template>
<script>
import dayjs from "dayjs";
import { Message } from "element-ui";
import { mapGetters } from "vuex";
import { getUserCustomList } from "@/api/customer";
import { getOrderList, getOrderDetails } from "@/api/order";
import { selectBusinessShipping } from "@/api/shipping";
import { basicStatistics } from '@/api/chart';

import orderShare from "@/components/orderShare";

export default {
  name: "Tracking",
  components: {
    orderShare
  },
  data() {
    return {
      mapType: 0,
      shareOrderId: null,
      orderInfo: null,
      orderinfosShow: false,
      tableSelectedShow: null,
      params: {
        type: "Company",
        name: "",
        ci: "",
        time: [],
        obj: null,
      },
      companyName: "",
      companyNameCopy: "",
      mapParams: {
        orders: [],
        startTime: "",
        endTime: "",
        timeNow: "",
      },
      dateRange: [dayjs().startOf('year'), dayjs()],
      searchParams: {
        orderProgress: [],
        companyId: "",
        currentPage: 1,
        pageSize: 5,
        total: 0,
        ci: "",
        sortField: "",
        sortType: ""
      },
      statusEnum: [
        {
          orderStatus: "0",
          updateTime: "",
          user: null,
          size: "large"
        }, {
          orderStatus: "1",
          updateTime: "",
          user: null,
          size: "large"
        }, {
          orderStatus: "2",
          updateTime: "",
          user: null,
          size: "large"
        }, {
          orderStatus: "3",
          updateTime: "",
          user: null,
          size: "large"
        }, {
          orderStatus: "4",
          updateTime: "",
          user: null,
          size: "large"
        }, {
          orderStatus: "5",
          updateTime: "",
          user: null,
          size: "large"
        }, {
          orderStatus: "6",
          updateTime: "",
          user: null,
          size: "large"
        }, 
      ],
      loading: false,
      isShowTable: false,
      isScaleTable: false,
      cardCi: "",
      arr: [],
      order: null,
      orderInfoBlock: false,
      isClick: true,
      sortBy: ""
    }
  },
  activated() {
    const query = this.$route.query;
    if (query.ci) {
      this.params.type = "CI";
      setTimeout(() => {
        this.params.ci = query.ci;
        this.searchParams.currentPage = 1;
        this.currentShipInfo({ orderId: query.orderId });
      }, 500);
    }
  },
  watch: {
    '$route': {
      handler() {
        this.clear();
      },
      deep: true
    },
    'params.type'() {
      this.clear();
    },
    'dateRange': {
      handler() {
        this.nextFilterDialog();
      },
      deep: true
    },
    sortBy(val) {
      this.searchParams.sortField = val.split("|")[0];
      this.searchParams.sortType = val.split("|")[1];
      this.nextFilterDialog();
    },
    "searchParams.orderProgress"() {
      this.nextFilterDialog();
    }
  },
  computed: {
    ...mapGetters(['companyCurrentType', 'orderProgress', 'allNodeList', 'isDefaultNode']),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    arrList() {
      return this.arr;
    },
    isSearchHandler() {
      if (this.params.type == "Company" && this.params.name && !this.params.obj) {
        return true;
      } else {
        return false;
      }
    },
    stateDrop() {
      return function ({progress = 0, progressList = []}) {
        if(progress == progressList[0].progress) {
          return 'producing'
        } else if(progress == progressList[progressList.length]?.progress) {
          return 'destination'
        } else {
          return 'inTransit'
        }
      }
    }
  },
  methods: {
    goShareOrder() {
      this.$refs.orderShare.open();
    },
    visibleDropdownChange(val) {
      if(!val) {
        this.tableSelectedShow = null;
      }
    },
    customerMapSelect(value) {
      this.companyName = value.label;
    },
    async getCurrenCompanyOrders() {
      try {
        if (this.dateRange) {
          this.searchParams["startTime"] = dayjs(this.dateRange[0]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.searchParams["endTime"] = dayjs(this.dateRange[1]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          delete this.searchParams["startTime"];
          delete this.searchParams["endTime"];
        }
        if(this.params.type === 'Company') {
          basicStatistics({ 
            startTime: this.searchParams["startTime"],
            endTime: this.searchParams["endTime"],
            companyId: this.searchParams.companyId
          }).then((data) => {
            this.orderInfo = data;
          });
        }
        this.loading = true;
        let obj = JSON.parse(JSON.stringify(this.searchParams));
        obj.orderProgress = obj.orderProgress.join(",")
        const response = await getOrderList({ 
          ...obj, 
          companyType: null,
          ci: this.cardCi
        });
        this.arr = response.list.map((item, index) => {
          return {
            ...item,
            index: (this.searchParams.currentPage - 1) * this.searchParams.pageSize + index + 1,
            checked: item.ci === this.$route.query?.ci
          };
        });
        this.searchParams.total = response.total;
        this.loading = false;
        if(this.$route.query.ci) {
          this.search();
        }
      } catch (error) {
        console.error(error);
      }
    },
    nextFilterDialog() {
      if (this.params.type == "Company") {
        this.searchParams.ci = "";
        this.searchParams.companyId = this.params.obj?.value;
      } else {
        this.cardCi = this.params.ci;
        this.searchParams.ci = this.params.ci;
        this.searchParams.companyId = "";
      }
      this.orderInfoBlock = false;
      this.isShowTable = true;
      this.isScaleTable = false;
      this.getCurrenCompanyOrders();
    },
    clearCompanyName() {
      this.companyName = "";
    },
    clear() {
      this.params.name = "";
      this.params.ci = "";
      this.params.company = "";
      this.params.obj = null;
      this.params.time = [];
      this.arr = [];
      this.isShowTable = false;
      this.isScaleTable = false;
      this.orderInfoBlock = false;
      this.mapParams.timeNow = Date.now();
      this.mapParams.orders = [];
      this.mapParams.startTime = "";
      this.mapParams.endTime = "";
      this.dateRange = [dayjs().startOf('year'), dayjs()];
      this.searchParams.companyId = "";
      this.searchParams.ci = "";
      this.searchParams.orderProgress = [];
      this.searchParams.currentPage = 1;
      this.searchParams.pageSize = 5;
      this.searchParams.total = 0;
    },
    search() {
      this.mapParams.orders = [];
      this.arrList.forEach(row => {
        if(row.checked) {
          this.mapParams.orders.push({
            orderId: row.orderId,
            progress: row.progress,
            index: row.index,
            ci: row.ci
          });
        }
      })
      this.mapParams.timeNow = Date.now();
    },
    changePageSizeHandler(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.getCurrenCompanyOrders();
    },
    changePageHandler(page) {
      this.searchParams.currentPage = page;
      this.getCurrenCompanyOrders();
    },
    async getCompanyArraySelect(name, cb) {
      const { list } = await getUserCustomList({
        companyName: name,
        companyType: this.companyCurrentType == 1 ? 0 : (this.companyCurrentType == 2 ? (this.mapType == 1 ? 1 : 0) : 1),
        currentPage: 1,
        pageSize: 10000
      });
      cb(
        list.map((data) => {
          return {
            value: data.companyId,
            label: data?.aliasName || data?.name
          };
        })
      );
    },
    async currentShipInfo(e) {
      this.orderInfoBlock = !e.isActive;
      this.isShowTable = e.isActive;
      this.loading = true;
      if (!e.isActive) {
        const orderData = await getOrderDetails(e.orderId);
        this.shareOrderId = orderData.orderId;
        this.loading = false;
        const shippingData = await selectBusinessShipping(e.orderId);
        orderData.progressList.forEach((row, idx) => {
          if(row.date && (orderData.progress == row.progress)) {
            orderData.progressList[idx] = {
              ...row,
              color: "#48AC42",
              icon: `iconfont ${this.computedNodeList(row.progress).icon}`,
              active: true,
              size: "normal"
            }
          } else if(row.date) {
            orderData.progressList[idx] = {
              ...row,
              color: "#67A691",
              icon: "el-icon-check",
              active: false,
              size: "large"
            }
          }
        })
        console.log(orderData);
        this.order = { ...orderData, ...shippingData };
        this.loading = false;
      } else {
        this.loading = false;
      }
      this.loading = false;
    },
    setObj(e) {
      this.params.obj = e;
      this.nextFilterDialog();
    }
  }
};
</script>

<style lang="less" scoped>
.translateButton {
  /deep/ .special_tabs {
    background: #ffffff !important;
    border: none !important;
  }
}
.customerMapSearch {
  position: absolute;
  top: 78px;
  left: 20px;
  z-index: 2;
}
.mapShowModeTabs {
  position: absolute;
  top: 78px;
  right: 20px;
  z-index: 2;
  display: flex;
  background: #ffffff;
  padding: 2px 4px;
  border-radius: 4px;
  .item {
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 12px;
    color: #637381;
    cursor: pointer;
    i {
      font-size: 12px;
    }
    &.active {
      color: #ffffff;
      background: #122545;
    }
  }
}
.lineDemarcation {
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
  height: 1px;
  background: #E0E4EA;
}

.scaleTableBox {
  position: absolute;
  top: 44px;
  z-index: 11;
  width: 89px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  .btns {
    gap: 15px;
    .headerBtn {
      &:first-child {
        .icon-log-out {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.btns {
  display: flex;
  gap: 4px;
  .headerBtn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .btn {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      transition: all .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 14px;
        color: #757D8A;
        &::before {
          color: #757D8A;
        }
      }
      &:hover {
        background: #E3E9F1;
      }
    }
  }
}
.orderListDialog {
  width: 376px;
  position: absolute;
  top: 44px;
  z-index: 11;
  background: #ffffff;
  border-radius: 4px;
  transition: all .3s;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 43px;
    border-bottom: 1px solid #E0E4EA;
    padding: 0 10px 0 15px;
    span {
      font-size: 16px;
      font-weight: 600;
      color: #122545;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .left {
      display: flex;
      align-items: center;
      /deep/ .box {
        width: 28px;
        height: 28px;
        i {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .el-icon-arrow-left {
      font-size: 20px;
      color: #757D8A;
      position: relative;
      left: -5px;
      cursor: pointer;
    }
  }
  .content {
    padding: 10px 0;
    max-height: calc(100vh - 200px);
    overflow: overlay;
    &.listContent {
      padding: 10px 15px;
    }
    .pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .total {
        font-size: 14px;
        color: #122545;
        cursor: pointer;
        span {
          color: #232638;
          font-weight: 600;
        }
      }
      /deep/ .el-pagination {
        padding: 0;
      }
    }
    .seeMore {
      width: 100%;
      height: 40px;
      text-align: center;
      color: #076F49;
      font-size: 12px;
      line-height: 40px;
      cursor: pointer;
      i {
        font-size: 12px;
        transition: all .3s;
        display: inline-block;
      }
    }
    .userInfos {
      margin-top: 6px;
      padding: 0 15px;
      overflow: hidden;
      transition: all .3s;
      .purchaseCompany {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .purchaseCompanyInfo {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: #757D8A;
          line-height: 17px;
          span {
            color: #122545;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .el-image {
          width: 40px;
          height: 30px;
          border-radius: 4px;
        }
      }
      .obw-grid {
        display: flex;
        flex-wrap: wrap;
        // gap: 20px;
      }

      .left-block {
        margin-bottom: 20px;
        width: 50%;
        overflow: hidden;
      }

      .left-block .lb-a1 {
        font-size: 12px;
        font-weight: 400;
        color: #A3B0C6;
        margin-bottom: 1px;
        line-height: 17px;
      }

      .left-block .lb-a2 {
        font-size: 12px;
        font-weight: 400;
        color: #17181c;
        font-weight: 600;
        line-height: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .orderStatus {
      margin-top: 20px;
      position: relative;
      .statusHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 3px;
        span {
          font-size: 12px;
          line-height: 17px;
          &:first-child {
            font-weight: 600;
            color: #122545;
          }
          &:last-child {
            color: #637381;
          }
        }
      }
      .userAvator {
        display: flex;
        gap: 4px;
        margin-top: 7px;
        align-items: center;
        font-size: 12px;
        &.show {
          display: flex;
        }
        &.hide {
          display: none;
        }
      }

      /deep/ .el-timeline {
        .el-timeline-item {
          padding: 13px 15px 8px 15px;
          &:hover {
            .userAvator {
              display: flex;
            }
          }
          cursor: pointer;
          // &:last-child {
          //   padding-bottom: 0;
          // }
          .el-timeline-item__timestamp {
            margin-top: 0;
          }
          .el-timeline-item__tail {
            left: 20px;
            z-index: 1;
          }
          .el-timeline-item__wrapper {
            padding-left: 22px;
          }
          .el-timeline-item__node--large {
            left: 14px;
            top: 11px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-timeline-item__icon {
              font-size: 12px;
              transform: scale(.8);
            }
          }
          .el-timeline-item__node--normal {
            transform: scale(1.7);
            left: 15px;
            z-index: 2;
            .el-timeline-item__icon {
              // font-size: 12px;
              transform: scale(.5);
            }
          }
          &:hover {
            background: #F7F9FC;
          }
        }
      }
    }
    .orderListBox {
      display: flex;
      flex-direction: column;
      border: 1px solid #E0E4EA;
      margin-top: 10px;
      border-radius: 4px;
      max-height: calc(100vh - 400px);
      overflow: overlay;
      .item {
        padding: 14px 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E0E4EA;
        cursor: pointer;
        transition: all .3s;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: #F7F9FC;
        }
        .orderContent {
          .ciNo {
            display: flex;
            align-items: center;
            .index {
              border-radius: 50px;
              font-size: 12px;
              color: #ffffff;
              padding: 0 5px;
              line-height: 14px;
              background: #2A3B58;
              margin-right: 4px;
              height: 16px;
              display: flex;
              align-items: center;
            }
            .ci {
              font-size: 14px;
              color: #122545;
              font-weight: 500;
              line-height: 20px;
              width: 250px;
            }
          }
          .stateAmount {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 17px;
            margin-top: 8px;
            .amount {
              color: #A3B0C6;
            }
            .state {
              color: #122545;
              display: flex;
              align-items: center;
            }
          }
          .createTime {
            font-size: 12px;
            line-height: 17px;
            color: #637381;
            margin-top: 8px;
          }
        }
        .action {
          display: flex;
          flex-direction: column;
          .icon-xiangqing {
            font-size: 16px;
            color: #076F49;
            margin-bottom: 20px;
          }
        }
      }
    }
    .orderInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .total {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #F7F9FC;
        border-radius: 4px;
        padding: 6px;
        font-size: 12px;
        color: #A3B0C6;
        line-height: 17px;
        cursor: pointer;
        span {
          font-size: 14px;
          font-weight: 700;
          color: #122545;
          line-height: 16px;
          display: flex;
          align-items: center;
          i {
            font-size: 20px;
            color: #076F49;
          }
        }
      }
      .orderNum {
        display: flex;
        gap: 10px;
        .item {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: #A3B0C6;
          line-height: 17px;
          cursor: pointer;
          padding: 6px;
          border-radius: 4px;
          &.active {
            background: #F7F9FC;
          }
          span {
            color: #122545;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            &::before {
              content: "";
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin-right: 6px;
            }
          }
          &:nth-child(1) {
            span {
              &::before {
                background: #DC3545;
              }
            }
          }
          &:nth-child(2) {
            span {
              &::before {
                background: #48AC42;
              }
            }
          }
          &:nth-child(3) {
            span {
              &::before {
                background: #2E4793;
              }
            }
          }
        }
        .el-dropdown {
          .item {
            span {
              &::before {
                background: #48AC42;
              }
            }
          }
        }
      }
    }
    .form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      /deep/ .el-date-editor {
        border: none;
        padding: 3px;
        .el-range__icon {
          position: relative;
          top: 1px;
        }
      }
      .all {
        font-size: 12px;
        color: #076F49;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 12px;
          position: relative;
          margin-left: 2px;
          top: 1px;
        }
      }
    }
  }
}

#map {
  width: 100%;
  height: calc(100vh);
  z-index: 1;
}

.search_bar {
  position: absolute;
  top: 78px;
  left: 20px;
  z-index: 2;
  background-color: transparent;
  display: flex;
  flex-direction: row;
}

.dropState {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  &.producing {
    background: #DC3545;
  }
  &.destination {
    background: #2E4793;
  }
  &.inTransit {
    background: #48AC42;
  }
}

.orderStatus {

}
</style>
